import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/workpath0/themes/gatsby-theme-zebra/src/components/mdx-layout-pages.js";
import { Flex, Box } from "rebass";
import Tiles from "gatsby-theme-zebra/src/components/tiles";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`I offer textile workshops at local venues, or I can visit your group. Please email me to discuss your workshop requirements. As well as holding a City and Guilds Level 3 Diploma in Stitched Textiles I am a fully qualified teacher (PGCE).`}</p>
    <h2 {...{
      "id": "textile-landscapes"
    }}>{`Textile Landscapes`}</h2>
    <p>{`Textiles can be layered and stitched to create wonderfully textured landscapes. Think collage using fabric and thread. In this one day workshop you will create a small picture or wall hanging using machine stitch and raw edge finishes which can then be quilted, embroidered and embellished. Working from a picture or simply your imagination, you will create a beautiful textile landscape by layering and stitching to a background fabric. `}</p>
    <Tiles columns={2} mdxType="Tiles">
      <ol>
        <li parentName="ol">
          <p parentName="li"><span parentName="p" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "600px"
              }
            }}>{`
      `}<a parentName="span" {...{
                "className": "gatsby-resp-image-link",
                "href": "/static/ec462f5b37c5b47020d785477f5ad30a/ee0c9/textile-landscapes-1.jpg",
                "style": {
                  "display": "block"
                },
                "target": "_blank",
                "rel": ["noopener"]
              }}>{`
    `}<span parentName="a" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "153.615221987315%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAfABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAECBAX/xAAXAQADAQAAAAAAAAAAAAAAAAAAAQME/9oADAMBAAIQAxAAAAHlvXFZ4joCLaWcgkb/AP/EABsQAAMAAgMAAAAAAAAAAAAAAAABEhARAhMh/9oACAEBAAEFApI8k6tkpErD5Io3hn//xAAYEQADAQEAAAAAAAAAAAAAAAAAARESIf/aAAgBAwEBPwGs0zhEf//EABcRAAMBAAAAAAAAAAAAAAAAAAABERL/2gAIAQIBAT8BiIjTNM//xAAcEAABAwUAAAAAAAAAAAAAAAAAEDFBAREhMqH/2gAIAQEABj8CHS5mqSMb8SSD/8QAHRAAAwACAwEBAAAAAAAAAAAAAAERITFBUaFxgf/aAAgBAQABPyHXLg0yVPqDYwbF0ZgXGm/0xavBPFJ8EJ7Y2uPRV0P/2gAMAwEAAgADAAAAECMiTP/EABYRAAMAAAAAAAAAAAAAAAAAAAAQYf/aAAgBAwEBPxBwqf/EABgRAAIDAAAAAAAAAAAAAAAAAAABEEFh/9oACAECAQE/EIBLs3P/xAAgEAEBAAIBAwUAAAAAAAAAAAABEQAhQTFRkWGBwdHh/9oACAEBAAE/EBlGu03h7caqJi2/OJmffx5xmkjoGCCA8n5zgZuxsclLnlv1kceinWEdR424sip74Z38Wf/Z')",
                    "backgroundSize": "cover",
                    "display": "block"
                  }
                }}></span>{`
  `}<img parentName="a" {...{
                  "className": "gatsby-resp-image-image",
                  "alt": "First example of a textile landscape",
                  "title": "First example of a textile landscape",
                  "src": "/static/ec462f5b37c5b47020d785477f5ad30a/82472/textile-landscapes-1.jpg",
                  "srcSet": ["/static/ec462f5b37c5b47020d785477f5ad30a/5aa83/textile-landscapes-1.jpg 150w", "/static/ec462f5b37c5b47020d785477f5ad30a/afcd2/textile-landscapes-1.jpg 300w", "/static/ec462f5b37c5b47020d785477f5ad30a/82472/textile-landscapes-1.jpg 600w", "/static/ec462f5b37c5b47020d785477f5ad30a/2d017/textile-landscapes-1.jpg 900w", "/static/ec462f5b37c5b47020d785477f5ad30a/c35de/textile-landscapes-1.jpg 1200w", "/static/ec462f5b37c5b47020d785477f5ad30a/ee0c9/textile-landscapes-1.jpg 2365w"],
                  "sizes": "(max-width: 600px) 100vw, 600px",
                  "loading": "lazy"
                }}></img>{`
  `}</a>{`
    `}</span></p>
        </li>
        <li parentName="ol">
          <p parentName="li"><span parentName="p" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "600px"
              }
            }}>{`
      `}<a parentName="span" {...{
                "className": "gatsby-resp-image-link",
                "href": "/static/8d976c6cf3173b27b9f06c4390dfc05c/ee0c9/textile-landscapes-3.jpg",
                "style": {
                  "display": "block"
                },
                "target": "_blank",
                "rel": ["noopener"]
              }}>{`
    `}<span parentName="a" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "153.615221987315%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAfABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAEDBAL/xAAYAQADAQEAAAAAAAAAAAAAAAAAAQMCBP/aAAwDAQACEAMQAAABVHFwZnEV6mclQymz/8QAGxAAAwACAwAAAAAAAAAAAAAAAAECAxMREiP/2gAIAQEAAQUCUofVHmbJQ07NAqNnA8wpljxDk//EABkRAQACAwAAAAAAAAAAAAAAAAABERITIf/aAAgBAwEBPwHioa4YP//EABkRAQACAwAAAAAAAAAAAAAAAAACEwERIf/aAAgBAgEBPwG2K2LjWH//xAAgEAABAgUFAAAAAAAAAAAAAAAAMuEBITGRoQIQEUFx/9oACAEBAAY/Aqk9QuFyXHoxXA44nJ1bZUT/xAAdEAEAAgICAwAAAAAAAAAAAAABABEhMVGBQWFx/9oACAEBAAE/IaGk+D9scpqFGnKsw1BA8Qu8uVeOovsHchesgYYEDvqBcv/aAAwDAQACAAMAAAAQ9P8Aj//EABgRAAMBAQAAAAAAAAAAAAAAAAABETFh/9oACAEDAQE/EGs06C1SIP/EABoRAAMAAwEAAAAAAAAAAAAAAAABESExYfD/2gAIAQIBAT8QhtnYarIvdP/EAB8QAQACAQUAAwAAAAAAAAAAAAEAESExQVGBoWFx0f/aAAgBAQABPxA4xF1Zi+6gtKaWji8mRQ/av0hWINlKdcbZ4iwWuDXtqMal5LQYBZeoB4QDT8NWZmhL2i1sfOqaON7R+S/vByT/2Q==')",
                    "backgroundSize": "cover",
                    "display": "block"
                  }
                }}></span>{`
  `}<img parentName="a" {...{
                  "className": "gatsby-resp-image-image",
                  "alt": "Second example of a textile landscape",
                  "title": "Second example of a textile landscape",
                  "src": "/static/8d976c6cf3173b27b9f06c4390dfc05c/82472/textile-landscapes-3.jpg",
                  "srcSet": ["/static/8d976c6cf3173b27b9f06c4390dfc05c/5aa83/textile-landscapes-3.jpg 150w", "/static/8d976c6cf3173b27b9f06c4390dfc05c/afcd2/textile-landscapes-3.jpg 300w", "/static/8d976c6cf3173b27b9f06c4390dfc05c/82472/textile-landscapes-3.jpg 600w", "/static/8d976c6cf3173b27b9f06c4390dfc05c/2d017/textile-landscapes-3.jpg 900w", "/static/8d976c6cf3173b27b9f06c4390dfc05c/c35de/textile-landscapes-3.jpg 1200w", "/static/8d976c6cf3173b27b9f06c4390dfc05c/ee0c9/textile-landscapes-3.jpg 2365w"],
                  "sizes": "(max-width: 600px) 100vw, 600px",
                  "loading": "lazy"
                }}></img>{`
  `}</a>{`
    `}</span></p>
        </li>
      </ol>
    </Tiles>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "600px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/f81b82c78e3ff99ce6a6c54b3ea94443/cd902/textile-landscapes-2.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAwABAv/EABYBAQEBAAAAAAAAAAAAAAAAAAECA//aAAwDAQACEAMQAAAB5NMNBlmf/8QAGBABAQADAAAAAAAAAAAAAAAAAQAREiL/2gAIAQEAAQUCkuoZM2pf/8QAFhEBAQEAAAAAAAAAAAAAAAAAABEh/9oACAEDAQE/AcV//8QAFhEAAwAAAAAAAAAAAAAAAAAAAAER/9oACAECAQE/AYyM/8QAGBABAAMBAAAAAAAAAAAAAAAAAAEhQTH/2gAIAQEABj8C7DGMhan/xAAbEAEAAgMBAQAAAAAAAAAAAAABABExgZEhYf/aAAgBAQABPyFFyO4hh5lRqPCACxyan2f/2gAMAwEAAgADAAAAEBw//8QAFhEBAQEAAAAAAAAAAAAAAAAAAQAx/9oACAEDAQE/EBwyL//EABYRAAMAAAAAAAAAAAAAAAAAAAEQUf/aAAgBAgEBPxCRR//EAB0QAQEAAgEFAAAAAAAAAAAAAAERACExQWFxwdH/2gAIAQEAAT8Q2lR6p94oq/P1izrXtMW0opLTIaIMVo5Hltvkuf/Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Third example of a textile landscape",
            "title": "Third example of a textile landscape",
            "src": "/static/f81b82c78e3ff99ce6a6c54b3ea94443/82472/textile-landscapes-2.jpg",
            "srcSet": ["/static/f81b82c78e3ff99ce6a6c54b3ea94443/5aa83/textile-landscapes-2.jpg 150w", "/static/f81b82c78e3ff99ce6a6c54b3ea94443/afcd2/textile-landscapes-2.jpg 300w", "/static/f81b82c78e3ff99ce6a6c54b3ea94443/82472/textile-landscapes-2.jpg 600w", "/static/f81b82c78e3ff99ce6a6c54b3ea94443/2d017/textile-landscapes-2.jpg 900w", "/static/f81b82c78e3ff99ce6a6c54b3ea94443/c35de/textile-landscapes-2.jpg 1200w", "/static/f81b82c78e3ff99ce6a6c54b3ea94443/cd902/textile-landscapes-2.jpg 3968w"],
            "sizes": "(max-width: 600px) 100vw, 600px",
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "washaway-textiles"
    }}>{`Washaway Textiles`}</h2>
    <p>{`Create your own textiles using water soluble fabric. During the workshop you will make a bowl using thread, fabric scraps and water soluble fabric. You will then experiment with fabric and stitch to create your own accessory or textile which could be used for embellishing stitching projects. To get the most out of this workshop you will need to be able to do free motion stitching on your sewing machine. This can be a half day or one day workshop.`}</p>
    <Tiles columns={2} mdxType="Tiles">
      <ol>
        <li parentName="ol">
          <p parentName="li"><span parentName="p" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "600px"
              }
            }}>{`
      `}<a parentName="span" {...{
                "className": "gatsby-resp-image-link",
                "href": "/static/e3f0b74869c7631b73c84197ee34a3a6/b05f5/water-soluble-sample.jpg",
                "style": {
                  "display": "block"
                },
                "target": "_blank",
                "rel": ["noopener"]
              }}>{`
    `}<span parentName="a" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "150.03453038674033%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAeABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAIDAf/EABYBAQEBAAAAAAAAAAAAAAAAAAIAA//aAAwDAQACEAMQAAABz5yMnqC4ZygUV//EABoQAAIDAQEAAAAAAAAAAAAAAAERAAISIUH/2gAIAQEAAQUC7npJso5TpU9Y1cOxejaZc//EABsRAAICAwEAAAAAAAAAAAAAAAABERICISNR/9oACAEDAQE/AX4dDJ1UMo3uT//EABsRAAICAwEAAAAAAAAAAAAAAAABEVECAyMx/9oACAECAQE/AVZzoWM+C2pKD//EABgQAAMBAQAAAAAAAAAAAAAAAAAQIREx/9oACAEBAAY/AikNdUNOL//EAB0QAAMBAAIDAQAAAAAAAAAAAAABESExgVFhcZH/2gAIAQEAAT8hnB0JUNxKXRKjwYpiGpucHT9FuSJpnyGxMETXoo0mnsnfB//aAAwDAQACAAMAAAAQ7Dv+/8QAHREBAAICAgMAAAAAAAAAAAAAAQARIZExUWGx8P/aAAgBAwEBPxBCVL83r7qBXCaihhz6i6nKf//EABwRAAIBBQEAAAAAAAAAAAAAAAERACExUWGB8P/aAAgBAgEBPxACAXXK79mMKMex8mxZg8lp/8QAHBABAQACAwEBAAAAAAAAAAAAAREAITFBUYGh/9oACAEBAAE/EBCIJpF8x4ASA63kRcOmTHZQsoz7ivWImdvv5kSWznTDyRRg7h1iSRzKaPmEoCAdTeLiX1Gp5hczKjs8zhVDwHWf/9k=')",
                    "backgroundSize": "cover",
                    "display": "block"
                  }
                }}></span>{`
  `}<img parentName="a" {...{
                  "className": "gatsby-resp-image-image",
                  "alt": "An example using water soluble fabric",
                  "title": "An example using water soluble fabric",
                  "src": "/static/e3f0b74869c7631b73c84197ee34a3a6/82472/water-soluble-sample.jpg",
                  "srcSet": ["/static/e3f0b74869c7631b73c84197ee34a3a6/5aa83/water-soluble-sample.jpg 150w", "/static/e3f0b74869c7631b73c84197ee34a3a6/afcd2/water-soluble-sample.jpg 300w", "/static/e3f0b74869c7631b73c84197ee34a3a6/82472/water-soluble-sample.jpg 600w", "/static/e3f0b74869c7631b73c84197ee34a3a6/2d017/water-soluble-sample.jpg 900w", "/static/e3f0b74869c7631b73c84197ee34a3a6/c35de/water-soluble-sample.jpg 1200w", "/static/e3f0b74869c7631b73c84197ee34a3a6/b05f5/water-soluble-sample.jpg 2896w"],
                  "sizes": "(max-width: 600px) 100vw, 600px",
                  "loading": "lazy"
                }}></img>{`
  `}</a>{`
    `}</span></p>
        </li>
        <li parentName="ol">
          <p parentName="li"><span parentName="p" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "600px"
              }
            }}>{`
      `}<a parentName="span" {...{
                "className": "gatsby-resp-image-link",
                "href": "/static/b6f6d1e0d8fb404ff3a4094533c7d4f1/698f0/blue-thread-bowl.jpg",
                "style": {
                  "display": "block"
                },
                "target": "_blank",
                "rel": ["noopener"]
              }}>{`
    `}<span parentName="a" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "66.65938387590124%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAMEAgX/xAAUAQEAAAAAAAAAAAAAAAAAAAAC/9oADAMBAAIQAxAAAAHpJpQxUaCv/8QAGRAAAwEBAQAAAAAAAAAAAAAAAQIDAAQh/9oACAEBAAEFAnoEJ6Fw9FJigXmVTv/EABURAQEAAAAAAAAAAAAAAAAAABAh/9oACAEDAQE/Aaf/xAAVEQEBAAAAAAAAAAAAAAAAAAAQIf/aAAgBAgEBPwGH/8QAGRABAAMBAQAAAAAAAAAAAAAAAQAQIRES/9oACAEBAAY/AtJgtcYPpr//xAAbEAACAgMBAAAAAAAAAAAAAAABEQBBECFRMf/aAAgBAQABPyFSJ44AdJcBBSdGMaRwio0qu4//2gAMAwEAAgADAAAAEEDv/8QAGBEAAgMAAAAAAAAAAAAAAAAAARARITH/2gAIAQMBAT8QInWL/8QAFhEBAQEAAAAAAAAAAAAAAAAAIQEQ/9oACAECAQE/EIHP/8QAHBABAAICAwEAAAAAAAAAAAAAAQARITFBUXGB/9oACAEBAAE/EDbl1JznJBGGCiih6HcRFsDyHsOUBvAfDLCMgDEa1f3MLDdz/9k=')",
                    "backgroundSize": "cover",
                    "display": "block"
                  }
                }}></span>{`
  `}<img parentName="a" {...{
                  "className": "gatsby-resp-image-image",
                  "alt": "A blue thread bowl made using water soluble fabric",
                  "title": "A blue thread bowl made using water soluble fabric",
                  "src": "/static/b6f6d1e0d8fb404ff3a4094533c7d4f1/82472/blue-thread-bowl.jpg",
                  "srcSet": ["/static/b6f6d1e0d8fb404ff3a4094533c7d4f1/5aa83/blue-thread-bowl.jpg 150w", "/static/b6f6d1e0d8fb404ff3a4094533c7d4f1/afcd2/blue-thread-bowl.jpg 300w", "/static/b6f6d1e0d8fb404ff3a4094533c7d4f1/82472/blue-thread-bowl.jpg 600w", "/static/b6f6d1e0d8fb404ff3a4094533c7d4f1/2d017/blue-thread-bowl.jpg 900w", "/static/b6f6d1e0d8fb404ff3a4094533c7d4f1/c35de/blue-thread-bowl.jpg 1200w", "/static/b6f6d1e0d8fb404ff3a4094533c7d4f1/698f0/blue-thread-bowl.jpg 4577w"],
                  "sizes": "(max-width: 600px) 100vw, 600px",
                  "loading": "lazy"
                }}></img>{`
  `}</a>{`
    `}</span></p>
          <p parentName="li"><span parentName="p" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "600px"
              }
            }}>{`
      `}<a parentName="span" {...{
                "className": "gatsby-resp-image-link",
                "href": "/static/b0e87790fde6f8ed4cefaaaa46ba3bbe/f86f9/delicate-thread-bowl.jpg",
                "style": {
                  "display": "block"
                },
                "target": "_blank",
                "rel": ["noopener"]
              }}>{`
    `}<span parentName="a" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "66.66666666666666%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAMCBAX/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAAB0YWFWPAP/8QAGhAAAgMBAQAAAAAAAAAAAAAAAQIAAxIQE//aAAgBAQABBQKx8T2HGGgtSqZ//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGRABAAMBAQAAAAAAAAAAAAAAAQAQESIy/9oACAEBAAY/AjlZ5axmlf/EABwQAAICAgMAAAAAAAAAAAAAAAABESEQYTFBUf/aAAgBAQABPyHlkH2jyYRqBLLEWAnbx//aAAwDAQACAAMAAAAQmO//xAAXEQADAQAAAAAAAAAAAAAAAAAQESEx/9oACAEDAQE/EHcH/8QAFhEAAwAAAAAAAAAAAAAAAAAAEBEh/9oACAECAQE/EIh//8QAHBABAQACAgMAAAAAAAAAAAAAAREAITFRQWFx/9oACAEBAAE/EAfAGhB6xRN9ICVvrBoLTyWzBpryYd0XDSfM33n/2Q==')",
                    "backgroundSize": "cover",
                    "display": "block"
                  }
                }}></span>{`
  `}<img parentName="a" {...{
                  "className": "gatsby-resp-image-image",
                  "alt": "A delicate thread bowl made with water soluble fabric",
                  "title": "A delicate thread bowl made with water soluble fabric",
                  "src": "/static/b0e87790fde6f8ed4cefaaaa46ba3bbe/82472/delicate-thread-bowl.jpg",
                  "srcSet": ["/static/b0e87790fde6f8ed4cefaaaa46ba3bbe/5aa83/delicate-thread-bowl.jpg 150w", "/static/b0e87790fde6f8ed4cefaaaa46ba3bbe/afcd2/delicate-thread-bowl.jpg 300w", "/static/b0e87790fde6f8ed4cefaaaa46ba3bbe/82472/delicate-thread-bowl.jpg 600w", "/static/b0e87790fde6f8ed4cefaaaa46ba3bbe/2d017/delicate-thread-bowl.jpg 900w", "/static/b0e87790fde6f8ed4cefaaaa46ba3bbe/c35de/delicate-thread-bowl.jpg 1200w", "/static/b0e87790fde6f8ed4cefaaaa46ba3bbe/f86f9/delicate-thread-bowl.jpg 4512w"],
                  "sizes": "(max-width: 600px) 100vw, 600px",
                  "loading": "lazy"
                }}></img>{`
  `}</a>{`
    `}</span></p>
        </li>
      </ol>
    </Tiles>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      